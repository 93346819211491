;(function () {
  // Create <style> for iframe
  const resizerStyle = document.createElement('style')
  resizerStyle.textContent = /* css */ `
		.asiagoit-embed-webcam__iframe {
			width: 1px;
			min-width: 100%;
		}
	`
  document.head.appendChild(resizerStyle)

  // Process each asiagoit-embed-webcam div
  document.querySelectorAll('.asiagoit-embed-webcam').forEach(embed => {
    const embedId = embed.dataset.embed

    embed.style.removeProperty('width')
    embed.style.removeProperty('height')

    /// Create <iframe>
    const iframe = document.createElement('iframe')
    iframe.classList.add('asiagoit-embed-webcam__iframe')
    iframe.scrolling = 'no'
    iframe.frameBorder = 'no'

    const src = [
      window.location.protocol,
      '//',
      window.location.hostname === '127.0.0.1' ? window.location.host : 'www.asiago.it',
      window.location.hostname === '127.0.0.1' ? '/dist/index.html' : '/embed/webcam/',
      '?embed=',
      embedId,
    ]
    iframe.src = src.join('')

    embed.appendChild(iframe)
  })

  // Create <script> for iframe-resizer
  const resizerScript = document.createElement('script')
  resizerScript.src =
    'https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.2/iframeResizer.min.js'

  const allowedOrigins = ['https://www.asiago.it']
  if (window.location.hostname === '127.0.0.1') {
    allowedOrigins.push(`${window.location.protocol}//${window.location.host}`)
  }

  resizerScript.onload = function () {
    iFrameResize(
      {
        checkOrigin: allowedOrigins,
      },
      '.asiagoit-embed-webcam__iframe'
    )
  }
  document.body.appendChild(resizerScript)
})()
